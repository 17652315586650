<template>

    <div class="level_index level_indexI">

        <div class="search">
            <el-form :inline="true">


                <el-form-item label="组织机构">

                    <el-cascader v-model="wasteInfo.dep_id" placeholder="请选择组织机构" :options="depList" filterable
                        clearable :props="{
                        label:'name', children:'children',checkStrictly:'true',value:'id' }"></el-cascader>

                </el-form-item>



                <el-form-item label="是否开通联单上传">
                    <el-select clearable v-model="wasteInfo.is_hazardous" placeholder="请选择状态" class="SelfSelect">

                        <el-option label="全部" :value="''"></el-option>
                        <el-option label="开通" :value="'1'"></el-option>
                        <el-option label="未开通" :value="'0'"></el-option>



                    </el-select>
                </el-form-item>





                <el-form-item prop="title">
                    <el-date-picker v-model="wasteInfo.hazardous_time" type="daterange" range-separator="至"
                        start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy 年 MM 月 dd 日"
                        value-format="yyyy-MM-dd" style="height: 36px; display: flex;align-items: baseline;">
                    </el-date-picker>
                </el-form-item>



                <el-form-item>

                    <el-button type="primary" @click="searchName">
                        <i class="fa fa-search" aria-hidden="true"></i>
                        查询
                    </el-button>

                    <!-- <el-button type="primary" @click="toSearch" v-if="getPurviewNew('sys:hazardous:add')">
                        <i class="fa fa-plus" aria-hidden="true"></i>
                        生成联单
                    </el-button> -->

                </el-form-item>


                <!-- <el-form-item v-if="getPurviewNew('sys:hazardous:export')">
                    <json-excel :data="json_data" :fields="json_fields" worksheet="全部" name="危废联单.xls"
                        :header="[header]" :footer="[footer]">
                        <el-button type="primary">导出危废联单</el-button>
                    </json-excel>
                </el-form-item> -->

            </el-form>
        </div>





        <el-table class="el-tableII FanfuTableBox " :data="data.list" stripe height="100%" border ref="multipleTable">

            <el-table-column show-overflow-tooltip label="序号" type="index" align="center" fixed="left" />

            <el-table-column show-overflow-tooltip prop="name" label="医院名称" align="left" min-width="180" />






            <el-table-column show-overflow-tooltip prop="" label="是否开通" align="left" min-width="100">
                <template slot-scope="scope">
                    <span class="circleS" v-if="scope.row.is_hazardous==0"> 未开通 </span>
                    <span class="circleS Green" v-else> 已开通 </span>
                </template>
            </el-table-column>




            <el-table-column show-overflow-tooltip prop="" label="是否使用" align="left" min-width="100">
                <template slot-scope="scope">
                    <span class="circleS Green" v-if="scope.row.use_time.length>0"> 是 </span>
                    <span class="circleS" v-else> 否 </span>
                </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip prop="hazardous_time" label="联单开始时间" align="left" min-width="180" />
            <el-table-column show-overflow-tooltip prop="use_time" label="使用时间" align="left" min-width="180" />









            <!-- <el-table-column show-overflow-tooltip prop="name" label="医院名称" align="left" min-width="180" /> -->


            <!-- <el-table-column show-overflow-tooltip prop="dep_name" label="企业名称" align="left" min-width="150" /> -->

            <!-- <el-table-column show-overflow-tooltip prop="item_name" label="类型" align="left" min-width="60" /> -->

            <!-- <el-table-column show-overflow-tooltip prop="item_name" label="废物名称" align="left" min-width="100" /> -->

            <!-- <el-table-column show-overflow-tooltip prop="item_code" label="废物代码" align="left" min-width="100" /> -->

            <!-- <el-table-column show-overflow-tooltip prop="" label="包装类型" align="left" min-width="100">
                <template slot-scope="scope">
                    <span v-if="scope.row.packing_id==5">
                        利器盒
                    </span>
                    <span v-if="scope.row.packing_id==6">
                        包装袋
                    </span>
                </template>
            </el-table-column> -->


            <!-- <el-table-column show-overflow-tooltip prop="quantity" label="总数量" align="left" /> -->

            <!-- <el-table-column show-overflow-tooltip prop="quantity" label="包装数量" align="left" min-width="100" /> -->

            <!-- <el-table-column show-overflow-tooltip prop="tripartite_weight" label="第三方单位接收量(公斤)" align="left"
                min-width="150" /> -->


            <!-- <el-table-column show-overflow-tooltip prop="dep_number" label="接收单位数" align="left" min-width="110" /> -->


            <!-- <el-table-column show-overflow-tooltip prop="this_weight" label="本单位产生量(公斤)" align="left" min-width="130" /> -->

            <!-- <el-table-column show-overflow-tooltip prop="create_time" label="上传时间" align="left" min-width="150" /> -->


            <el-table-column show-overflow-tooltip label="操作" min-width="80" align="center">
                <template slot-scope="scope">
                    <div class="operate">
                        <el-button @click="view(scope.row)">
                            详情
                        </el-button>

                    </div>
                </template>
            </el-table-column>

        </el-table>


        <el-pagination layout="total, prev, pager, next, jumper" background :current-page.sync="data.page"
            :page-size="data.size" :total="data.total" @current-change="handleCurrentChange" />

        <Search ref="searchRef" />

        <HazardousInfo ref="HazardousInfo" />

    </div>
</template>

<script>
    import Search from './components/HazardousSearch'

    import HazardousInfo from './components/HazardousInfo'

    import JsonExcel from 'vue-json-excel'

    import resizeDetector from 'element-resize-detector'


    import {
        mapState,
        mapMutations
    } from 'vuex'



    export default {
        components: {
            Search,
            HazardousInfo,
            JsonExcel
        },
        data() {
            return {

                data: {
                    list: [],
                    page: 1,
                    size: 15,
                    total: 0
                },

                depList: [], //机构

                wasteInfo: {
                    hazardous_time: "",
                    dep_id: "",
                    is_hazardous: ""
                },

                header: "",
                footer: "",
                json_fields: {},
                json_data: [],

            }
        },

        computed: {
            ...mapState('m_item', [
                'LevelList',
                'PositionList',
                'DepList',
                'DepTree',
                'HealthList',
                'Province',
                'City',
                'District',
                'RoleList',
                'Depcate',
                'ItemType'
            ])
        },

        mounted() {
            this.modifyLayout();
        },

        created() {
            this.modifyLayout();

            this.getList();


            // 数据处理
            this.DataProcessing()

            this.getdeptree()
            setTimeout(() => {
                this.depList = this.DepTree
            }, 1000)


        },

        watch: {

            "wasteInfo": {
                deep: true, //深度监听设置为 true
                handler: function(newVal, oldVal) {

                    this.toSearch()

                }
            },

        },

        methods: {

            getList() {

                var a = {
                    ...this.wasteInfo
                }

                if (this.wasteInfo.dep_id && this.wasteInfo.dep_id.length > 0) {
                    a.dep_id = this.wasteInfo.dep_id.slice(-1).toString()
                }

                if (this.wasteInfo.hazardous_time && this.wasteInfo.hazardous_time.length > 0) {
                    a.hazardous_time = this.wasteInfo.hazardous_time.toString()
                }

                a.page_size = this.data.size
                a.page = this.data.page

                this.$http.get('/api/hazardous/count', a).then((res) => {

                    if (res.code == 200) {

                        this.data.list = res.data.data

                        this.data.total = res.data.total

                        setTimeout(() => {
                            this.modifyLayout();
                        }, 1100)

                    }

                    if (res.code != 200) { //请求错误
                        this.$message.error(res.msg);

                    }
                }).catch((err) => {
                    console.log(err)
                });

            },


            // 数据处理
            DataProcessing() {

                // this.$http.get('/api/hazardous', {

                //     create_time: this.created_time.toString(),

                //     dep_id: JSON.parse(localStorage.getItem('user')).dept_id, //机构标识id
                //     item_id: "", //医废类型id

                //     page_size: 9999,
                //     page: 1

                // }).then((res) => {

                //     if (res.code == 200) {

                //         var val = res.data.data

                //         this.header = "危废联单"

                //         this.json_fields = {
                //             "联单编号": {
                //                 field: 'hazardous_no',
                //                 callback: (val) => {
                //                     if (val) {
                //                         return `'${val}`
                //                     } else {
                //                         return ``
                //                     }
                //                 }
                //             },
                //             "联单状态": {
                //                 field: 'state',
                //                 callback: (val) => {
                //                     if (val == 1) {
                //                         return "正常"
                //                     } else {
                //                         return "异常"
                //                     }
                //                 }
                //             },
                //             "废物俗称": "item_name",
                //             "废物代码": "item_code",
                //             "填领时间": "create_time",
                //             // "转移时间"	
                //             "移出量(kg)": "weight",
                //             // "办结时间"	
                //             "接收量(kg)": "weight",
                //             "产废单位": "dep_name",
                //             "经营单位": {
                //                 field: '',
                //                 callback: () => {
                //                     return "天津瀚洋汇和环保科技有限公司"
                //                 }
                //             },
                //         }

                //         this.json_data = val

                //     }

                //     if (res.code != 200) { //请求错误
                //         this.$message.error(res.msg);
                //     }

                // }).catch((err) => {
                //     console.log(err)
                // });


            },

            searchName() {

                this.toSearch()

            },

            toSearch() {
                // this.$refs.searchRef.open()

                this.data.page = 1
                this.getList()
            },



            view(row) {
                console.log(row)

                this.$router.push("/pages/Hazardous/Hazardous?id=" + row.id)




                // this.$refs.HazardousInfo.info(row)
            },

            edit(row) {
                console.log(row)

                // this.$refs.searchRef.edit(row)

            },

            del(row) {

                //     console.log(row)
                //     const h = this.$createElement;
                //     this.$msgbox({
                //         title: '删除',
                //         message: h('p', null, [
                //             h('span', null, '该操作将删除所选数据，您确定要删除吗？删除后将无法恢复！'),
                //         ]),
                //         showCancelButton: true,
                //         confirmButtonText: '删除',
                //         cancelButtonText: '取消',
                //         beforeClose: (action, instance, done) => {
                //             if (action === 'confirm') {
                //                 // instance.confirmButtonLoading = true;
                //                 // instance.confirmButtonText = '执行中...';
                //                 // setTimeout(() => {
                //                 //     done();
                //                 //     setTimeout(() => {
                //                 //         instance.confirmButtonLoading = false;
                //                 //     }, 300);
                //                 // }, 3000);
                //                 this.$http.del('/api/hazardous/' + row.id).then((res) => {
                //                     console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);
                //                     if (res.code == 200) {
                //                         this.getList()
                //                     }
                //                     if (res.code != 200) { //请求错误
                //                         this.$message.error(res.msg);
                //                     }
                //                     // setTimeout(() => {
                //                     done();
                //                     setTimeout(() => {
                //                         instance.confirmButtonLoading = false;
                //                     }, 300);
                //                     // }, 3000);
                //                 }).catch((err) => {
                //                     console.log(err)
                //                 });
                //             } else {
                //                 done();
                //             }
                //         }
                //     }).then(action => {
                //         // this.$message({
                //         //     type: 'info',
                //         //     message: 'action: ' + action
                //         // });
                //     });

            },




            modifyLayout() {
                const erd = resizeDetector();

                erd.listenTo(document.getElementsByClassName("search"), element => {
                    const width = element.offsetWidth;
                    const height = element.offsetHeight;
                    var a = 141 + 60 - 35 + height - 30;
                    if ($(".level_indexI .FanfuTableBox")[0]) {
                        $(".level_indexI .FanfuTableBox")[0].setAttribute("style", 'height: calc(100vh - ' + a +
                            'px) !important');
                    }
                    var b = 141 + 60 + 33 + height - 30;
                    if ($(".level_indexI .el-table")[0]) {
                        $(".level_indexI .el-table")[0].setAttribute("style", 'height: calc(100vh - ' + b +
                            'px) !important');
                    }
                });
            },

            handleCurrentChange(val) {
                console.log(`当前页: ${val}`);
                this.data.page = val
                this.getList()
            },

        },
    }
</script>

<style lang="scss" scoped>
    .el-button--success {
        color: #ffffff;
        background-color: #5BD995;
        border-color: #5BD995;
    }

    .circleS {
        // border: 1px red solid;
        // box-sizing: border-box;

        color: #666;
        background-color: #e6e6e6;
        width: 60px;
        height: 30px;
        line-height: 30px;
        display: inline-block;
        text-align: center;
        border-radius: 30px;
    }

    .circleS.Green {
        color: #fff;

        background-color: #5BD995;

    }
</style>


<style scoped>
    /*  .level_indexI .FanfuTableBox {
    border: 1px blue dotted;
    box-sizing: border-box;
  } */
    /*  .level_indexI .el-table.el-table--fit.el-table--striped.el-table--border.el-table--fluid-height.el-table--scrollable-y.el-table--enable-row-transition.el-table--mini { */

    /*  .level_indexI .el-table {
    border: 1px red dotted;
    box-sizing: border-box;
  } */
</style>